@import url(https://fonts.googleapis.com/css?family=Quicksand:400,500,700&display=swap);
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Quicksand", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carrouselCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.carrouselCard h3 {
  font-size: 2.5em;
  font-weight: 700;
  color: #fab317;
  margin-bottom: 20px;
}

.carrouselCard h5 {
  font-size: 1.5em;
  font-weight: 500;
  color: #34393b;
  line-height: 1;
  margin-bottom: 5px;
}

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: none !important;
  padding-bottom: 10px !important;
}

.carrouselModal {
  top: 5px !important;
}

@media only screen and (min-width: 768px) {
  .carrouselModal {
    top: 100px !important;
  }
}

.loading-screen {
  min-height: 100vh;
  display: flex;
  height: 100%;
  flex: 1 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 150px;
  }
}

.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* ALL CONFIRM MODALS*/

.ant-modal-content {
  border-radius: 20px !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  text-align: center;
  font: 700 1.5em Quicksand !important;
  color: #34393b !important;
}

.ant-modal-confirm-content {
  margin-left: 0 !important;
  text-align: center;
  font: 500 1.25em Quicksand !important;
  color: #6e6e6e !important;
}

.ant-modal-confirm-btns {
  float: unset;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ant-modal-confirm-btns .ant-btn {
  font: 500 1.25em Quicksand !important;
  color: #fab317;
  border: none;
  box-shadow: none;
  text-shadow: none;
}

.ant-modal-confirm-btns .ant-btn:hover {
  background-color: unset;
  color: #fab317;
}

.ant-modal-confirm-btns .ant-btn-primary {
  background-color: unset !important;
}

.ant-modal-confirm-btns .ant-btn-primary:hover {
  background-color: unset !important;
  color: #fab317 !important;
}

.ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-confirm-btns .ant-btn-primary:focus {
  color: #fab317 !important;
}

.ant-modal-body h3 {
  text-align: center;
  font: 700 2em Quicksand;
  color: #34393b;
}

.ant-modal-body h5 {
  text-align: center;
  font: 500 1.25em Quicksand;
  color: #34393b;
}

.ant-modal-body .modalButton {
  margin: 10px 0;
  box-shadow: none;
  text-shadow: none;
  text-align: center;
  font: 700 1.5em Quicksand;
  color: #fff;

  height: unset;
  padding: 10px 15px;
  border-radius: 31px;
}

