@import url("https://fonts.googleapis.com/css?family=Quicksand:400,500,700&display=swap");

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Quicksand", sans-serif !important;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carrouselCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.carrouselCard h3 {
  font-size: 2.5em;
  font-weight: 700;
  color: #fab317;
  margin-bottom: 20px;
}

.carrouselCard h5 {
  font-size: 1.5em;
  font-weight: 500;
  color: #34393b;
  line-height: 1;
  margin-bottom: 5px;
}

.ant-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: none !important;
  padding-bottom: 10px !important;
}

.carrouselModal {
  top: 5px !important;
}

@media only screen and (min-width: 768px) {
  .carrouselModal {
    top: 100px !important;
  }
}

.loading-screen {
  min-height: 100vh;
  display: flex;
  height: 100%;
  flex: 1 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 150px;
  }
}

.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
}
