.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ALL CONFIRM MODALS*/

.ant-modal-content {
  border-radius: 20px !important;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  text-align: center;
  font: 700 1.5em Quicksand !important;
  color: #34393b !important;
}

.ant-modal-confirm-content {
  margin-left: 0 !important;
  text-align: center;
  font: 500 1.25em Quicksand !important;
  color: #6e6e6e !important;
}

.ant-modal-confirm-btns {
  float: unset;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ant-modal-confirm-btns .ant-btn {
  font: 500 1.25em Quicksand !important;
  color: #fab317;
  border: none;
  box-shadow: none;
  text-shadow: none;
}

.ant-modal-confirm-btns .ant-btn:hover {
  background-color: unset;
  color: #fab317;
}

.ant-modal-confirm-btns .ant-btn-primary {
  background-color: unset !important;
}

.ant-modal-confirm-btns .ant-btn-primary:hover {
  background-color: unset !important;
  color: #fab317 !important;
}

.ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-confirm-btns .ant-btn-primary:focus {
  color: #fab317 !important;
}

.ant-modal-body h3 {
  text-align: center;
  font: 700 2em Quicksand;
  color: #34393b;
}

.ant-modal-body h5 {
  text-align: center;
  font: 500 1.25em Quicksand;
  color: #34393b;
}

.ant-modal-body .modalButton {
  margin: 10px 0;
  box-shadow: none;
  text-shadow: none;
  text-align: center;
  font: 700 1.5em Quicksand;
  color: #fff;

  height: unset;
  padding: 10px 15px;
  border-radius: 31px;
}
